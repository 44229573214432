/** @jsx jsx */
/* 3rd party imports */
import { FunctionComponent } from 'react';
import { jsx } from 'theme-ui';
import { graphql, PageProps, Link } from 'gatsby';
import { FluidObject } from 'gatsby-image';

/* 1st party imports */
import Layout from '@/components/Layout';
import SEO from '@/components/SEO';
import Styles from '@/templates/PostsPage/styles';
import PostCard from '@/components/PostCard';

interface Query {
	posts: {
		edges: {
			node: {
				frontmatter: {
					slug: string;
					featured: boolean;
					date: string;
					title: string;
					featuredImage: string;
				}
				excerpt: string;
			}
		}[]
	}
	featuredImages: {
		edges: {
			node: {
				relativePath: string;
				childImageSharp: {
					fluid: FluidObject;
				}
			}
		}[]
	}
	genericImage: {
		childImageSharp: {
			fluid: FluidObject;
		}
	}
}

interface Context {
	limit: number;
	skip: number;
	numPages: number;
	currentPage: number;
}

interface Post {
	slug: string;
	title: string;
	date: string;
	excerpt: string;
	childImageSharp: {
		fluid: FluidObject;
	} | undefined;
}

const transformPosts = (
	recentPosts: Query['posts'],
	recentPostsImages: Query['featuredImages'],
): Post[] => {
	return recentPosts.edges.reduce<Post[]>((acc, postEdge) => {
		const { slug, title, date, featuredImage = undefined } = postEdge.node.frontmatter;
		const postImage = recentPostsImages.edges.find(imageEdge => {
			return imageEdge.node.relativePath === featuredImage;
		});
		const recentPost: Post = {
			slug,
			title,
			date,
			excerpt: postEdge.node.excerpt,
			childImageSharp: postImage?.node?.childImageSharp,
		};
		acc.push(recentPost);
		return acc;
	}, []);
};

const Template: FunctionComponent<PageProps<Query, Context>> = ({ data, pageContext }) => {
	const posts = transformPosts(
		data.posts,
		data.featuredImages,
	);
	const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? '/posts' : `/posts/${currentPage - 1}`;
    const nextPage = `/posts/${currentPage + 1}`;

	return (
		<Layout>
			<SEO title="Home"/>
			<h1 sx={Styles.title}>Posts</h1>
			<nav>
				{posts.map(post => {
					const { slug, title, excerpt, date: dateString } = post;
					const date = new Date(dateString);

					return (
						<PostCard
							key={post.slug}
							fluid={post.childImageSharp?.fluid ?? data.genericImage.childImageSharp.fluid}
							pathPrefix="/posts/"
							info={{slug, title, date, excerpt}}
							sx={Styles.post}
							imageSx={Styles.postImage}
							infoSx={Styles.postInfo}
						/>
					);
				})}
			</nav>
			<ul
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					alignItems: 'center',
					listStyle: 'none',
					padding: 0,
					marginTop: '6em',
				}}
			>
				{!isFirst && (
					<Link to={prevPage} sx={{color: 'layoutLink'}} rel="prev">
						← Previous Page
					</Link>
				)}
				{Array.from({ length: numPages }, (_, index) => (
					<li
						key={`pagination-number${index + 1}`}
						sx={{
							margin: 0,
						}}
					>
						<Link
							to={`/posts${index === 0 ? '' : '/' + (index + 1)}`}
							sx={{
								padding: '0.3em',
								textDecoration: 'none',
								color: index + 1 === currentPage ? 'background' : 'layoutLink',
								backgroundColor: index + 1 === currentPage ? 'primary' : '',
							}}
						>
							{index + 1}
						</Link>
					</li>
				))}
				{!isLast && (
					<Link to={nextPage} sx={{color: 'layoutLink'}} rel="next">
						Next Page →
					</Link>
				)}
			</ul>
		</Layout>
	);
};

export default Template;

export const postListQuery = graphql`
	query ($skip: Int!, $limit: Int!, $featuredImages: [String]!) {
		posts: allMarkdownRemark(
			sort: { fields: [frontmatter___date], order: DESC }
			limit: $limit
			skip: $skip
		) {
			edges {
				node {
					frontmatter {
						slug
						featured
						date
						title
						featuredImage
					}
					excerpt(format: PLAIN, pruneLength: 160)
				}
			}
		}
		featuredImages: allFile(filter: { relativePath: { in: $featuredImages } } ) {
			edges {
				node {
					relativePath
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		}
		genericImage: file(relativePath: { eq: "generic-coding.jpg" } ) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
	}
`;