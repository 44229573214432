/* 3rd party imports */
import { SxStyleProp } from 'theme-ui';

const title: SxStyleProp = {
	color: 'primary',
}

const post: SxStyleProp = {
	flexBasis: ['75%', '40%', '30%'],
	maxWidth: ['75%', '40%', '30%'],
	fontSize: '0.8em',
	marginBottom: '2em',
}

const postImage: SxStyleProp = {
	height: ['12em', '15em'],
}

const postInfo: SxStyleProp = {
	height: ['unset', '5em'],
}

export default {
	title,
	post,
	postImage,
	postInfo,
};